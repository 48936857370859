
import { Vue, Component } from "vue-property-decorator";
import { api } from "@/api";
import { IPatientProfile, IWoundCreate } from "@/interfaces";
import { readToken } from "@/store/main/getters";
import { readPatientID, readLanguage } from "@/store/admin/getters";
import { commitSwitchLanguage } from "@/store/admin/mutations";

@Component({
  components: {},
})
export default class PatientOverview extends Vue {
  public nameWound = "";
  public icdWound = "";
  public locationWound = "";
  public detailsWound = "";
  public patientID = 0;
  public searchICD = "";

  public getICD(search: string) {
    return [];
  }
  public async createWound() {
    const woundNew: IWoundCreate = {
      name: this.nameWound,
      location: this.locationWound,
      icd: this.icdWound,
      patient: this.patientID,
      details: this.detailsWound,
    };
    this.reset();
    api.createWound(readToken(this.$store), woundNew);
    this.$router.push({name: 'main-horse-overview', params: {id: this.patientID.toString()}});
  }
  public reset() {
    this.icdWound = "";
    this.nameWound = "";
    this.locationWound = "";
    this.detailsWound = "";
  }
  public mounted() {
    this.patientID = readPatientID(this.$store);
  }

  get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }
  
}
