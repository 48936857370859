var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[(_vm.language)?_c('v-card',[_c('v-card-title',[_c('h1',[_vm._v("Add a new body part:")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Name","single-line":""},model:{value:(_vm.nameWound),callback:function ($$v) {_vm.nameWound=$$v},expression:"nameWound"}}),_c('v-text-field',{attrs:{"label":"ICD 11 Code","single-line":""},model:{value:(_vm.icdWound),callback:function ($$v) {_vm.icdWound=$$v},expression:"icdWound"}}),_c('v-text-field',{attrs:{"label":"Location of the wound","single-line":""},model:{value:(_vm.locationWound),callback:function ($$v) {_vm.locationWound=$$v},expression:"locationWound"}}),_c('v-text-field',{attrs:{"label":"Details of the wound","single-line":""},model:{value:(_vm.detailsWound),callback:function ($$v) {_vm.detailsWound=$$v},expression:"detailsWound"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","to":{
          name: 'main-horse-overview',
          params: { id: this.patientID },
        }},on:{"click":_vm.reset}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.reset}},[_vm._v(" Reset ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.createWound}},[_vm._v(" Save ")])],1)],1):_c('v-card',[_c('v-card-title',[_c('h1',[_vm._v("Ein neues Körperteil hinzufügen:")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Name","single-line":""},model:{value:(_vm.nameWound),callback:function ($$v) {_vm.nameWound=$$v},expression:"nameWound"}}),_c('v-text-field',{attrs:{"label":"ICD 11 Code","single-line":""},model:{value:(_vm.icdWound),callback:function ($$v) {_vm.icdWound=$$v},expression:"icdWound"}}),_c('v-text-field',{attrs:{"label":"Ort der Wunde","single-line":""},model:{value:(_vm.locationWound),callback:function ($$v) {_vm.locationWound=$$v},expression:"locationWound"}}),_c('v-text-field',{attrs:{"label":"Details der Wunde","single-line":""},model:{value:(_vm.detailsWound),callback:function ($$v) {_vm.detailsWound=$$v},expression:"detailsWound"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","to":{
          name: 'main-horse-overview',
          params: { id: this.patientID },
        }},on:{"click":_vm.reset}},[_vm._v(" Schliessen ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.reset}},[_vm._v("Zurücksetzen")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.createWound}},[_vm._v("Sichern")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }